import React from 'react';

export default function DataContent({title,desc}) {
  return (
    <>
      {/* Content Section Start */}
      <section className="content_section section_padding ">
        <div className="container">
          <div className="row">
            {title && (
              <div className="content_heading">
                <h1 className="content_title">{title}</h1>
              </div>
            )}
            <div
              className="contect_desc"
              dangerouslySetInnerHTML={{
                __html: desc,
              }}
            ></div>
          </div>
        </div>
      </section>
      {/* Content Section End */}
    </>
  );
}
