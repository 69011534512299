import React from "react";

export default function Banner({Bnanerimage, image_alt, image_title}) {
  
  return (
    <>
      <section className="PageBanner_section ">
      <img
      src={Bnanerimage}
      alt={image_alt}
      title={image_title}
      className="bannerimg"
    />
      </section>
    </>
  );
}
