import React from "react";

export default function VideoForm({ IframeVideo ,SectionTitle}) {

  return (
    <>
      {/* Start Video Area  */}
      <section className="videoForm_section section_padding service_bg section_padding_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <hr className="before_border devider_border_color" />
            </div>
            <div className="col-md-6">
              <div className="section_title">
                <h2 className="title text-light">{SectionTitle}</h2>
              </div>
            </div>
            <div className="col-md-3">
              <hr className="before_border devider_border_color" />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-12 col-md-8 col-12">
              {/* Start Post List */}
              <div className="content-block post-default image-rounded ">
                <div
                  className="post-thumbnail"
                > <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${IframeVideo}`}
                title="Name Change Service in India | Name Change Agent in India"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe></div>
              </div>
              {/* End Post List */}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-md-4 col-12">
              <div className="row">
                <div className="form_id" id="form_id">
                  <iframe
                    width="100%"
                    height="480"
                    src="https://manage.yourdoorstep.co/forms/ticket"
                    frameBorder="0"
                    allowFullScreen
                    title="form"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Video Area */}
    </>
  );
}
