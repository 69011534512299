import React from "react";
import { NavLink } from "react-router-dom";

export default function BlogHomebox({ slug, lang, image, image_title, image_alt, date, title, tagline }) {
  

  return (
    <>
      <div className="col-lg-4 col-sm-6 my-2">
        <div className="blog_box">
          <div className="blog_img_box">
            <NavLink to={lang === "en" ? `/${slug}` : `/${lang}/${slug}`}>
              <img
                src={image}
                className="blog_img"
                alt={image_alt}
                title={image_title}
              />
            </NavLink>
          </div>
          <div className="Blog_desc_box">
            <p className="blot_date">
              <i className="fa fa-clock-o" aria-hidden="true"></i>
              {date}
            </p>
            <NavLink to={lang === "en" ? `/${slug}` : `/${lang}/${slug}`}>
              <h5 className=" blog_title">{title}</h5>
            </NavLink>
            <p className=" blog_desc">{tagline}</p>
          </div>
        </div>
      </div>
    </>
  );
}
